import React from "react";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <div className="container">
      <div className="col-2 home">
        <div>
          HI
          <h2>Im a front end developer</h2>
          <Link to="/contact">
            <button>
              Connect
              <span class="material-symbols-outlined">settings_ethernet</span>
            </button>
          </Link>
        </div>
        <div></div>
      </div>
    </div>
  );
};
