import { Routes, Route } from "react-router-dom";
import React from "react";

// Components and Pages
import { Home } from "../pages/Home";
import { Contact } from "../pages/Contact";
import { Work } from "../pages/Work";
import { Education } from "../pages/Education";
import About from "../pages/About";
import Header from "./Header";
import Footer from "./Footer";

function App() {
  return (
    <>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="work" element={<Work />} />
          <Route path="education" element={<Education />} />
          <Route path="contact" element={<Contact />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
}

export default App;
