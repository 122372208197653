import React from "react";

function Footer() {
  function copyFunction(current) {
    let element = current;
    let copyText = element.dataset.mail;
    // Clear after and then insert email
    if (element.nextSibling) {
      element.nextSibling.remove();
    } else {
      element.after(copyText);
    }
    navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
      if (result.state === "granted" || result.state === "prompt") {
        navigator.clipboard.writeText(copyText).then(() => {
          element.classList.add("copied");
          setTimeout(() => {
            element.classList.remove("copied");
          }, "5000");
        });
      }
    });
  }
  return (
    <footer>
      <div className="container">
        <a href="https://github.com/ThereseLevin">GitHub</a>
        <a href="https://www.linkedin.com/in/therese-levin/">LinkedIn</a>
        <a href="https://www.instagram.com/handgjortavtherese/">Instagram</a>
        <a href="mailto:therese.levin@outlook.com">
          <span
            onclick={() => {
              copyFunction(this);
            }}
            data-mail="therese.levin@outlook.com"
          >
            Email
          </span>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
