import { Link } from "react-router-dom";
import React from "react";

function Nav({ setOpenId }) {
  function activateNav(e) {
    let allActive = document.querySelectorAll(".active");
    allActive.forEach((element) => {
      element.classList.remove("active");
    });
    e.classList.toggle("active");
    setOpenId("");
  }
  return (
    <nav className="main-navigation">
      <ul>
        <Link to="/">
          <li
            className="active"
            onClick={(e) => {
              activateNav(e.target);
            }}
          >
            Home
          </li>
        </Link>
        <Link to="/about">
          <li
            onClick={(e) => {
              activateNav(e.target);
            }}
          >
            About
          </li>
        </Link>
        <Link to="/work">
          <li
            onClick={(e) => {
              activateNav(e.target);
            }}
          >
            Work
          </li>
        </Link>
        <Link to="/education">
          <li
            onClick={(e) => {
              activateNav(e.target);
            }}
          >
            Education
          </li>
        </Link>
        <Link to="/contact">
          <li
            onClick={(e) => {
              activateNav(e.target);
            }}
          >
            Contact
          </li>
        </Link>
      </ul>
    </nav>
  );
}

export default Nav;
