import Nav from "./Nav";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [openId, setOpenId] = useState("");
  return (
    <header>
      <Link to="/">
        <div className="logo">
          Portfolio for <br></br>
          <i>Therese B Levin</i>
        </div>
      </Link>
      <span
        id={openId}
        onClick={() => {
          setOpenId("open-menu");
        }}
        class="material-symbols-outlined hamburger"
      >
        lunch_dining
      </span>
      <span
        id={openId && "open"}
        onClick={() => {
          setOpenId("");
        }}
        class="material-symbols-outlined close"
      >
        cancel
      </span>
      <Nav setOpenId={setOpenId} />
    </header>
  );
}

export default Header;
